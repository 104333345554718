angular
    .module('annexaApp')
    .component('annexaDossierDocs',{
        templateUrl: './components/tram/annexa-dossier-docs/annexa-dossier-docs.html',
        controller:['$scope', 'DccumentsFactory', 'Language', 'TramFactory', 'TableFilterFactory', '$filter', 'AnnexaPermissionsFactory', 'AnnexaDocumentActionsButtonFactory', '$state', 'GlobalDataFactory', '$rootScope', 'RouteStateFactory', function ($scope, DccumentsFactory, Language, TramFactory, TableFilterFactory, $filter, AnnexaPermissionsFactory, AnnexaDocumentActionsButtonFactory, $state, GlobalDataFactory, $rootScope, RouteStateFactory) {
            var vm = this;
            
            var showStyledDocumentTypeAcronym = $rootScope.app.configuration.show_styled_document_type_acronym.value;
            vm.languageColumn = Language.getActiveColumn();
            
            vm.filter = [
                { id: 'code', type: 'text', order: 0, label: 'global.literals.code' },
                { id: 'name', type: 'text', order: 1, label: 'global.literals.title' },
                { id: 'type', type: 'select', order: 2, label: 'global.literals.docType', dataType: 'LOCAL', data: GlobalDataFactory.documentTypes, addAll: true, nameProperty: Language.getActiveColumn() },
                { id: 'relationType', type: 'select', order: 3, label: 'global.tram.literals.documentRelationType', dataType: 'LOCAL', data: GlobalDataFactory.documentRelationTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true },
                { id: 'createdDate', type: 'dateRange', order: 4, label: 'global.literals.creation_date', callAux: true },
                { id: 'transactionType', type: 'select', order: 5, label: 'global.tram.literals.transaction_type', dataType: 'LOCAL', data: GlobalDataFactory.transactiontypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true }
            ];

            var routeState = RouteStateFactory.getRouteState($state.current);
            
            if(routeState) {
            	vm.filter = routeState.state;
            }

            var getFilterCall = function() {
                var filterCall = {
                    document: TableFilterFactory.getFilterCall(vm.filter),
                    dossierTransaction: { dossier: { id: vm.dossierId } }
                };

                var callAux = TableFilterFactory.getFilterCallAux(vm.filter);
                if(callAux && callAux.transactionType && callAux.transactionType.id) {
                    filterCall.dossierTransaction.transactionType = { id: callAux.transactionType.id };
                }
                if(callAux && callAux.relationType && callAux.relationType.id) {
                    filterCall.relationType = callAux.relationType.id;
                }

                return filterCall;
            }

            var getFilterCallAux = function () {
            	var filterCallAux = TableFilterFactory.getFilterCallAux(vm.filter);

                return filterCallAux;
            }
            var documentTypeColumn = new IconFilterColumn($scope, 'filterData', 'document.docStatus',
                [
                    new IconSearchOption('all', '', 'fa-minus fa-stack-1x', 'fa-minus', 'global.literals.all'),
                    new IconSearchOption('NEW','NEW', 'fa-circle-o text-blue fa-lg', 'fa-circle-o text-blue fa-lg', 'global.documentState.NEW'),
                    new IconSearchOption('NEW_AND_ATTACHMENT','NEW_AND_ATTACHMENT', 'fa-adjust text-blue fa-lg', 'fa-adjust text-blue fa-lg', 'global.documentState.NEW_AND_ATTACHMENT'),
                    new IconSearchOption('PENDING_SIGN','PENDING_SIGN', 'fa-circle text-blue fa-lg', 'fa-circle text-blue fa-lg', 'global.documentState.PENDING_SIGN'),
                    new IconSearchOption('SIGNED','SIGNED', 'fa-circle text-success fa-lg', 'fa-circle text-success fa-lg', 'global.documentState.SIGNED'),
                    new IconSearchOption('REJECTED','REJECTED', 'fa-circle text-danger fa-lg', 'fa-circle text-danger fa-lg', 'global.documentState.REJECTED'),
                    new IconSearchOption('COMPLETE','COMPLETE', 'fa-dot-circle-o text-success fa-lg', 'fa-dot-circle-o text-success fa-lg', 'global.documentState.COMPLETE')
                ], 'tableDocs');

            var actionsTitle = function () {
                return '<div class="column-actions">' + $filter('translate')('global.literals.actions') + '</div>';
            }

            var relationTypeRender = function(data, type, object, meta) {
                return $filter('translate')('global.tram.documentRelationType.' + data);
            };
            
            var actionsRender = function (data, type, full, meta) {
                return '<annexa-document-actions-button module="EXPED" dossier-transaction-document="tableObjects[' + full.id + ']" transaction-init="tableObjects[' + full.id + '].dossierTransaction" list="true" object-to-call="objectToCall"></annexa-document-actions-button>';
            }

            $scope.$on('SendToSignModalFinished', function(event, args) {
                vm.tableDefinition.reloadInternalData(false, true);
                $rootScope.$broadcast('SendToSignModalFinishedFromTable', args);
            });

            $scope.$on('documentActionButtonExecuted', function(event ,args) {
                vm.tableDefinition.reloadInternalData(false,true);
                $rootScope.$broadcast('documentActionButtonExecutedFromTable', args);
            });

            $scope.$on('allowGenerateFromContentDocumentTypeAddedToTransaction', function(event, args){
            	vm.tableDefinition.reloadInternalData(false,true);
            });
            $scope.$on('annexaBoxDocumentsTramUpdateCounterReloadTable', function(event, args){
            	vm.tableDefinition.reloadInternalData(true,true);
            });
            $scope.$on('created_multiple_notifications', function (event, args) {
       		   $rootScope.$broadcast('created_multiple_notifications_from_document', args);
            });

            $scope.$on('createdRegisterEntry', function (event, args) {
        		   $rootScope.$broadcast('createdRegisterEntry_from_document', args);
            });
            
            this.$onInit = function () {
            	if(vm.modify == undefined || vm.modify == null){
            		vm.modify = false;
            	}
                vm.tableDefinition = {
                    id: ((vm.modify)?'tableDocsTramModify':'tableDocsTramView'),
                    origin: 'tram',
                    objectType: 'DossierTransactionDocument',
                    sortName: 'createdDate',
                    sort: [[((vm.modify)?8:7), 'asc']],
                    filterCall: getFilterCall(),
                    filterCallAux: getFilterCallAux(),
                    filterCallFunc: getFilterCall,
                    filterCallAuxFunc: getFilterCallAux,
                    columns: [
                        { id: 'document.publicDocument', width: '40px', column: new BooleanColumn($filter, 'global.documentState.public', 'public'), sortable: false },
                        { id: 'document.docStatus', width: '40px', title: $filter('translate')('global.literals.state'), render: documentTypeColumn.getRender },
                        { id: 'document.type', width: '40px', column: showStyledDocumentTypeAcronym ? new IconColumn($filter, 'global.literals.type', 'acronym', vm.languageColumn) : new ObjectTextColumn($filter, 'global.literals.type', 'acronym') , sortable: false },
                        { id: 'document.id', width: '250px', column: new UIRouterStateColumn($filter, 'global.literals.code', 'annexa.doc.documents.view({code: \'[data]\'})', undefined, 'var code = full.document.id; if(full.document.code) { code = full.document.code.substr((full.document.code.indexOf(full.document.id) - 2), full.document.id.toString().length + 2); } code;', true) },
                        { id: 'document.name', width: '250px', column: new UIRouterStateColumnDataLink($filter, 'global.literals.title', 'annexa.doc.documents.view({code: \'[data]\'})', 'full.document.id', true) },
                        { id: 'dossierTransaction.transactionType.' + Language.getActiveColumn(), title: $filter('translate')('global.tram.literals.transaction_type') },
                        { id: 'dossierTransaction.' + Language.getActiveColumn(), title: $filter('translate')('global.literals.transaction') },
                        { id: 'document.createdDate', column: new DateTimeColumn($filter, 'global.literals.creation_date') },
                        { id: 'relationType', title: $filter('translate')('global.tram.literals.documentRelationType'), render: relationTypeRender },
                        { id: 'actions', columnName: 'document.id', width: '120px', className: 'text-center', title: actionsTitle(), render: actionsRender, sortable: false }
                    ],
                    containerScope: $scope,
                    useTableObjects: true,
                    multipleActions: $state.current.name != 'annexa.tram.view.viewdossier' ? true : false,
                    functionMultipleActionsColumnShow: function(item) {
                        if (item && item.document) {
                            var havePermission = false;
                            if(item.dossierTransaction){
                                _.forEach(item.dossierTransaction.processProfiles, function (itemProf) {
                                    if(!havePermission){
                                        havePermission = AnnexaPermissionsFactory.haveSinglePermissionDual('process_dossier', itemProf.profile)
                                    }
                                })
                                if(!havePermission && TramFactory.Dossier && TramFactory.Dossier.dossierTransactions){
                                    _.forEach(TramFactory.Dossier.dossierTransactions, function (dt) {
                                        _.forEach(dt.processProfiles, function (dtItem) {
                                            if (!havePermission) {
                                                havePermission = AnnexaPermissionsFactory.haveSinglePermissionDual('process_dossier', dtItem.profile);
                                            }
                                        });
                                    });
                                }
                            }
                            if(!havePermission && item.document){
                                if(item.document.globalProcessProfileIds){
                                    var userProfiles = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile").toArray();
                                    var intersect = $linq(userProfiles).intersect(item.document.globalProcessProfileIds, "(x,y) => x == y.id").toArray();
                                    _.forEach(intersect, function (dtItem) {
                                        if (!havePermission) {
                                            havePermission = AnnexaPermissionsFactory.haveSinglePermissionDual('process_dossier', dtItem);
                                        }
                                    });
                                }
                                if(!havePermission) {
                                    havePermission = AnnexaPermissionsFactory.haveSinglePermissionDual('view_documents',item.document.profiles);
                                }
                            }
                            return havePermission;
                        }
                    },
                    functionMultipleActionsReload: function(items) {
                        var options = [];
                        var optionItems = [];

                        _.forEach(items, function(item) {
                            if(item.selected) {
                                var evaluatedOptions = AnnexaDocumentActionsButtonFactory.getShowOptions(item.document, item, item.dossierTransaction.actual, true);

                                if (options.length == 0) {
                                    options = evaluatedOptions;
                                } else {
                                    options = $linq(options).intersect(evaluatedOptions, "(x, y) => x == y").toArray();
                                }

                                optionItems.push(item);
                            }
                        });

                        if(options.length != 0) {
                            vm.tableDefinition.multipleActionsFunction(AnnexaDocumentActionsButtonFactory.getMultipleActionsButton(options, optionItems));
                        } else {
                            vm.tableDefinition.multipleActionsFunction();
                        }

                    },
                    parentObject: TramFactory.Dossier,
                    parentObjectAttribute: 'archiveClassification'                    
                };

            }

        }],
        bindings: {
            dossierId: '=',
            modify:'=?'
        }
    });