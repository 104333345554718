/**
 * Created by apop on 06/09/2018.
 */
angular
    .module('annexaApp')
    .component('annexaDossierEni', {
        templateUrl: './components/tram/annexa-dossier-eni/annexa-dossier-eni.html',
        bindings:{
            dossier: '='
        },
        controller: ['Language', '$rootScope', '$filter', 'TramFactory' , function (Language, $rootScope, $filter, TramFactory) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
            vm.versionNTI = $rootScope.app.configuration.default_eni_values.versionNTIdossier;

            this.$onInit = function () {
            	vm.isDossierEniPresent = $rootScope.app.configuration.default_eni_values.show_dossier_eni;

                if(vm.isDossierEniPresent && TramFactory.dossierEni) {
                    vm.expedienteEni = TramFactory.dossierEni;
                    if (vm.expedienteEni && vm.expedienteEni.fechaApertura) {
                        vm.eniFechaAperturaExpediente = $filter('date')(new Date(vm.expedienteEni.fechaApertura), 'dd/MM/yyyy HH:mm');
                    }
                    if (vm.expedienteEni && vm.expedienteEni.indiceExpediente && vm.expedienteEni.indiceExpediente.firmas.length != 0) {
                        vm.signTypeIndex = vm.expedienteEni.indiceExpediente.firmas;
                    } else {
                        vm.signTypeIndex = $rootScope.app.configuration.default_eni_values.signType.value;
                    }
                }
            }
        }]
    })