/**
 * Created by osirvent on 16/05/2017.
 */
angular
    .module('annexaApp')
    .component('annexaBudgetItems', {
        templateUrl: './components/tram/annexa-budget-items/annexa-budget-items.html',
        controller: ['TramFactory', 'RestService', 'Language', '$rootScope', '$filter', '$scope', 'DialogsFactory', function(TramFactory, RestService, Language, $rootScope,$filter, $scope, DialogsFactory) {
            var vm = this;
            
            $scope.$on('modifyBudgetItemsAccountingReferenceSelected', function(event, args) {
                if(args.budgetItems && args.budgetItems.length > 0) {
                	vm.data.budgetItems.length = 0;
                	var amount = 0;
                	var idAux = 1;
                	var areGes = args.budgetItems[0].budget.areGes;
                	var accountInstance = undefined;
                	if(TramFactory && TramFactory.userAccountInstances){
                		accountInstance = $linq(TramFactory.userAccountInstances).firstOrDefault(undefined, "x => x.code == '"+args.instanceCode+"'");
                	}
                	if(accountInstance && accountInstance.useManagementCenter){
	                	TramFactory.getBudgets(accountInstance.code, areGes).then(function (data) {
		            		vm.data.budgets.length = 0;
		            		if(data && data.length > 0){
		          			   data = JSOG.decode(data);
		          			   _.forEach(data, function(item){
		          				   vm.data.budgets.push(item);
		          			   })
	              		   	}
                        	_.forEach(args.budgetItems, function(item){
		                		var itemAux = $linq(vm.data.budgets).firstOrDefault(undefined,"x => x.code == '"+item.budget.code+"'");
		                		vm.data.budgetItems.push({idAux:idAux++,  budget:item.budget.code, availableCredit:((itemAux && itemAux.availableCredit)?itemAux.availableCredit:0.00), amount:((item.amount)?item.amount:0.00), amountAux:((item.amount)?item.amount:0.00), initialAmount:((item.amount)?item.amount:0.00), numLine:item.budget.numLine, reference:item.budget.reference, operationReference:item.budget.operationReference, areGes:item.budget.areGes});
		                		amount = amount + ((item.amount)?item.amount:0.00);
		                	});
		                	$rootScope.$broadcast('AnnexaChangeAmountBudget', {amount: amount});
		                	$rootScope.$broadcast('finishedAccountingReferenceSelected', { areGes: areGes });
            		   }).catch(function (error) {
	              		   if(error && error.data && error.data.message && error.data.message != 'Error call to PDI'){
	              			   DialogsFactory.error(error.data.message);
	             		   }else{
	             			  DialogsFactory.error($filter('translate')('global.operationsAndInvoices.budgetsError'));
	             		   }
	                   });
                	}else{
                		_.forEach(args.budgetItems, function(item){
                    		var itemAux = $linq(vm.data.budgets).firstOrDefault(undefined,"x => x.code == '"+item.budget.code+"'");
                    		vm.data.budgetItems.push({idAux:idAux++,  budget:item.budget.code, availableCredit:((itemAux && itemAux.availableCredit)?itemAux.availableCredit:0.00), amount:((item.amount)?item.amount:0.00), amountAux:((item.amount)?item.amount:0.00), initialAmount:((item.amount)?item.amount:0.00), numLine:item.budget.numLine, reference:item.budget.reference, operationReference:item.budget.operationReference, areGes:item.budget.areGes});
                    		amount = amount + ((item.amount)?item.amount:0.00);
                    	});
                    	$rootScope.$broadcast('AnnexaChangeAmountBudget', {amount: amount});
                    	$rootScope.$broadcast('finishedAccountingReferenceSelected', { areGes: areGes });
                	}
                }
            });
            
            $scope.$on('newAccountingInstanceSelected', function(event, args) {
                if(args.item && args.item.code) {
                	TramFactory.getBudgets(args.item.code).then(function (data) {
	            		vm.data.budgets.length = 0;
	            		if(data && data.length > 0){
	          			   data = JSOG.decode(data);
	          			   _.forEach(data, function(item){
	          				   vm.data.budgets.push(item);
	          			   })
              		   	}
	                	vm.data.budgetItems.length = 0;
	                	$rootScope.$broadcast('AnnexaChangeAmountBudget', {amount: 0});
              	   }).catch(function (error) {
              		   if(error && error.data && error.data.message && error.data.message != 'Error call to PDI'){
              			   DialogsFactory.error(error.data.message);
             		   }else{
             			  DialogsFactory.error($filter('translate')('global.operationsAndInvoices.budgetsError'));
             		   }
              		   vm.data.budgets.length = 0;
              		   vm.data.budgetItems.length = 0;
              		   $rootScope.$broadcast('AnnexaChangeAmountBudget', {amount: 0});
                   });
                }
            });
            
            
            $scope.$on('newAreGesSelected', function(event, args) {
                if(args.item && args.item.code && args.item.instanceCode) {
                	var accountInstance = undefined;
                	if(TramFactory && TramFactory.userAccountInstances){
                		accountInstance = $linq(TramFactory.userAccountInstances).firstOrDefault(undefined, "x => x.code == '"+args.item.instanceCode+"'");
                	}
                	if(accountInstance && accountInstance.useManagementCenter){
	                	TramFactory.getBudgets(args.item.instanceCode, args.item.code).then(function (data) {
		            		vm.data.budgets.length = 0;
		            		if(data && data.length > 0){
		          			   data = JSOG.decode(data);
		          			   _.forEach(data, function(item){
		          				   vm.data.budgets.push(item);
		          			   })
	              		   	}
		                	vm.data.budgetItems.length = 0;
		                	$rootScope.$broadcast('AnnexaChangeAmountBudget', {amount: 0});
	              	   }).catch(function (error) {
	              		   if(error && error.data && error.data.message && error.data.message != 'Error call to PDI'){
	              			   DialogsFactory.error(error.data.message);
	             		   }else{
	             			  DialogsFactory.error($filter('translate')('global.operationsAndInvoices.budgetsError'));
	             		   }
	              		   vm.data.budgets.length = 0;
	              		   vm.data.budgetItems.length = 0;
	              		   $rootScope.$broadcast('AnnexaChangeAmountBudget', {amount: 0});
	                   });
                	}
                }
            });
            
            this.budgetItemsColumns = [
            	{ id: 'budget', style: 'width:50%;', name: 'global.operationsAndInvoices.budgetItem', required: true, type: 'select', idProperty: 'code', nameProperty: 'name', list: vm.data.budgets, 
            	print:
            		function(item){
            			if(item && item.budget && item.budget.name){
            				return item.budget.name;
            			}else if(item && item.budget && typeof item.budget === 'string'){
            				var budget = $linq(vm.data.budgets).firstOrDefault(undefined, "x=> x.code == '"+item.budget+"'");
            				if(budget){
            					return budget.name;
            				}else{
            					return item.budget;          					
            				}
            			}
            		},
	            changeFunction: 
            		function($data, item){
            			if($data && vm.data.budgets && vm.data.budgets.length > 0 && item){
            				var budget = $linq(vm.data.budgets).firstOrDefault(undefined, "x=> x.code == '"+$data+"'");
            				if(budget){
            					item.availableCredit = budget.availableCredit;
            				}else{
            					item.availableCredit = 0.00;
            				}
            			}else if(item){
            				item.availableCredit = 0.00;
            			}
            		}
            	},
            	{ id: 'availableCredit', style: 'width:17%;', name: 'global.operationsAndInvoices.availableCredit', type: 'label', min: function () { return 0; }, max: function () {}, required: true },
                { id: 'amount', style: 'width:17%', name: 'global.operationsAndInvoices.amount', type: 'decimal', step:0.01, min: function (item) { return ((item && item.initialAmount)?-item.initialAmount:0.00); }, 
            	max: 
                	function (item) {
	                	if(item && item.availableCredit){
	                		return item.availableCredit
	                	}
            		}
                ,
	            changeFunction: 
            		function($data, item){
            			var amount = 0;
	            		item.amountAux = $data;
            			var exit = false;
            			_.forEach(vm.data.budgetItems, function(itemAux){
            				amount = amount + itemAux.amountAux;
            			});
            			$rootScope.$broadcast('AnnexaChangeAmountBudget', { amount: amount});
            		}
            	,
            	required: true,
            	checkRequiredFunction: 
            		function($data, item){
            			if($data && $data > item.availableCredit){
            				return $filter('translate')('global.validation.required');
            			}
            		}
            	},
                { id: 'actions', style: 'width:16%', name: '', type: 'actions' }
            ];
            
            this.addBudgetItem = function(){
            	var idAux = ((vm.data.budgetItems && vm.data.budgetItems.length > 0)?$linq(vm.data.budgetItems).max("x => x.idAux")+1:1);
            	if(!idAux){
            		idAux = 1;
            	}
            	return {idAux:idAux,  budget:{}, availableCredit:0.00, amount:0.00, amountAux:0.00, initialAmount:0.00};
            }
            
            this.updateBudgetItem = function(item, index){
            	var a=0;
            }
            
            this.removeBudgetItem = function(index){
            	vm.data.budgetItems.splice(index, 1);
            	var amount = 0;
    			_.forEach(vm.data.budgetItems, function(itemAux){
    				amount = amount + itemAux.amountAux;
    			});
    			$rootScope.$broadcast('AnnexaChangeAmountBudget', { amount: amount});
            }
            
           
            this.$onInit = function() {
         	   if(!vm.data){
         		   vm.data = {budgetItems: []};
         	   }
         	   if(!vm.data.budgets){
         		  vm.data.budgets = [];
         	   }else{
         		   vm.data.budgets.length = 0;
         	   }
     		  var accountInstance = ((TramFactory && TramFactory.userAccountInstances && TramFactory.userAccountInstances.length > 1)?undefined:TramFactory.userAccountInstances[0]);
              if(accountInstance){
            	 TramFactory.getBudgets(accountInstance.code, ((accountInstance.managementCenter && accountInstance.useManagementCenter)?accountInstance.managementCenter:undefined)).then(function (data) {
            		vm.data.budgets.length = 0;
            		if(data && data.length > 0){
          			   data = JSOG.decode(data);
          			   _.forEach(data, function(item){
          				   vm.data.budgets.push(item);
          			   })
            		}
                	vm.data.budgetItems.length = 0;
                	$rootScope.$broadcast('AnnexaChangeAmountBudget', {amount: 0});
            	 }).catch(function (error) {
            		   if(error && error.data && error.data.message && error.data.message != 'Error call to PDI'){
	            			   DialogsFactory.error(error.data.message);
	           		   }else{
	           			  DialogsFactory.error($filter('translate')('global.operationsAndInvoices.budgetsError'));
	           		   }
            		   vm.data.budgets.length = 0;
            		   vm.data.budgetItems.length = 0;
            		   $rootScope.$broadcast('AnnexaChangeAmountBudget', {amount: 0});
                 });
              }
            }
        }],
        bindings: {
        	data: '=?'
        }
    })